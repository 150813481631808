.room-container {
  /* justify-content: space-between; */
  /* justify-content: center; */
  width: 1200px;
}

@media (max-width: 1200px) {
  .room-container {
    width: 800px;
  }
}

p {
  font-size: 2rem;
  font-weight: 600;
}

.room-card {
  width: 360px;
  height: 360px;
  border-radius: 10px;
  margin: 40px 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), -2px -2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-out;
}

.room-card-container {
  width: 100%;
  height: 100%;
}

.room-card-inner img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}

.room-card:hover {
  transform: translateY(-5px);
  transition: all 0.3s ease-out;
}

.room-image {
  width: 360px;
  height: 248px;
  cursor: pointer;
}

.room-text {
  padding: 10px;
}

.room-dtype {
  font-size: 1.6rem;
  font-weight: 600;
}

.room-location {
  font-size: 1.1rem;
  margin-top: 10px;
}

.room-detail-container {
  width: 1200px;
  padding: 0 20px;
  margin-top: 100px;
  box-sizing: border-box;
}

.room-detail-images {
  justify-content: space-between;
  height: 430px;
  box-shadow: 0 4px 4px -4px rgb(184, 184, 184);
}

.room-detail-img {
  width: 560px;
  height: 373px;
}

.room-detail-img img {
  width: 100%;
  height: 100%;
}

.room-detail-bottom {
  margin-top: 80px;
}

.room-detail-info {
  justify-content: space-between;
  padding-top: 30px;
}

.room-detail-info-left,
.room-detail-info-right {
  width: 560px;
}

.room-detail-info-box {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.room-detail-info-title {
  font-size: 1.25rem;
  font-weight: 600;
  width: 90px;
}

.room-detail-info-text {
  padding-top: 2.5px;
  box-sizing: border-box;
}
.room-detail-info-text button {
  cursor: pointer;
  border: 1px solid #4e4981;
  margin-top: -2px;
  width: 120px;
  height: 30px;
  font-size: 1.2rem;
  font-weight: 800;
  border-radius: 5px;
  background: #fff;
  color: #4e4981;
}

.room-detail-location {
  margin-top: 80px;
}

.room-detail-map {
  margin-top: 30px;
  margin-bottom: 80px;
}

.room-register {
  width: 100%;
}

.room-register p {
  margin: 0;
  margin-bottom: 20px;
}

.room-register-container {
  width: 1200px;
  margin-top: 70px;
}

.room-register-right,
.room-register-left {
  width: 600px;
  padding: 0 35px;
  box-sizing: border-box;
}

.room-register-imgs {
  width: 530px;
  height: 300px;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  background: rgb(237, 237, 237);
  cursor: pointer;
  transition: 0.2s;
}

.room-register-imgs:hover {
  background: rgb(196, 196, 196);
  transition: 0.2s;
}

.room-register-imgs img {
  width: 125px;
  filter: invert(84%) sepia(0%) saturate(0%) hue-rotate(202deg) brightness(91%)
    contrast(92%);
}
.room-register-imgs:hover img {
  filter: invert(0%) sepia(82%) saturate(7485%) hue-rotate(92deg)
    brightness(103%) contrast(96%);
}

.room-register-info {
  width: 530px;
  margin-bottom: 40px;
}

.room-register-radio .room-register-info {
  margin: 60px 0;
}

.room-register-radio p {
  margin: 0;
}

.room-register input[type="text"] {
  width: 100%;
  padding: 10px;
  height: 50px;
  box-sizing: border-box;
  font-size: 1.25rem;
}

.room-register-thumbimgs {
  margin-top: 30px;
  width: 530px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.room-register-thumbimgs-list {
  margin-bottom: 20px;
  width: 120px;
  height: 90px;
}

.room-register-thumbimgs img {
  width: 120px;
  height: 90px;
}

.room-register-button {
  width: 530px;
}
.room-register-button button {
  border: 0;
  transition: 0.2s;

  border-radius: 5px;
  cursor: pointer;
  background: #4e4981;
  width: 150px;
  height: 50px;
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
  float: right;
}

.room-register-button button:hover {
  transition: 0.2s;
  background: #655fa7;
  transform: translateY(-2px);
}
